export class Farm {
  static get identificationTypeOptions() {
    return [
      {value: 'cow_no', caption: '牛番号'},
      {value: 'regulation_number_4', caption: '個体識別番号の拡大4桁'},
      {value: 'regulation_number_5', caption: '個体識別番号の下5桁'},
      {value: 'name', caption: '名前'}
    ];
  }

  static get cowNoPatternOptions() {
    return [
      {value: 'number', caption: '数字'},
      {value: 'alphanumeric', caption: '英数字'}
    ];
  }

  static get cowNoMatchingPatternOptions() {
    return [
      {value: 'complete', caption: '完全一致'},
      {value: 'prefix', caption: '前方一致'},
      {value: 'fuzzy', caption: 'あいまい検索'}
    ];
  }

  static get farmTypeMilk() {
    return 'MILK';
  }

  static isFarmTypeMilk(farmType) {
    return farmType === Farm.farmTypeMilk;
  }

  static get farmTypeBeef() {
    return 'BEEF';
  }

  static isFarmTypeBeef(farmType) {
    return farmType === Farm.farmTypeBeef;
  }

  static get ACTIVITY_COLUMNS() {
    return [
      {id: 'thresholdActivityRate1', label: '活動量指数閾値1', initial: 45},
      {id: 'thresholdActivityRate2', label: '活動量指数閾値2', initial: 55},
      {id: 'thresholdActivityRate3', label: '活動量指数閾値3', initial: 60},
      {id: 'thresholdActivityRate4', label: '活動量指数閾値4', initial: 65},
      {id: 'thresholdActivityRate5', label: '活動量指数閾値5', initial: 100},
      {id: 'feedThresholdRate', label: '採食低下閾値', initial: 2.0},
      {id: 'waterThresholdRate', label: '飲水低下閾値', initial: 2.0},
      {id: 'lieThresholdRate', label: '横臥増加閾値', initial: 2.0},
      {id: 'milkThresholdRate', label: '乳量低下閾値', initial: 2.0},
    ];
  }

  static get USER_DEFINED_OPTIONS() {
    return [
      {id: 'inseminators', label: '授精担当者'},
      {id: 'pregnancyAppraisers', label: '妊娠鑑定担当者'},
      {id: 'treatmentPersons', label: '治療担当者'},
      {id: 'vaccineTimings', label: 'ワクチンタイミング'},
      {id: 'judgePregnantTimings', label: '妊娠鑑定タイミング'},
      {id: 'producingAreas', label: '生産地'},
      {id: 'producingFarmNames', label: '生産農場名'},
      {id: 'raisingFarmNames', label: '育成農場名'},
      {id: 'transporters', label: '運送会社'},
      {id: 'buyers', label: '販売先'},
      {id: 'introduceStates', label: '導入時状態'},
      {id: 'birthStates', label: '出生時状態'},
      {id: 'otherReproductionWorks', label: 'その他処置内容'},
    ];
  }

  static get CERTIFICATE_AI_MENUS() {
    return [
      {id: 'standard', label: 'UM標準'},
      {id: 'kagoshima', label: '鹿児島版'},
    ];
  }

  constructor(data) {
    this.data = data;
  }

  id() {
    return this.data.id;
  }

  farmName() {
    return this.data.farmName;
  }

  useCowNo() {
    return this.data.identificationType === 'cow_no';
  }

  useRegulationNumber4() {
    return this.data.identificationType === 'regulation_number_4';
  }

  useRegulationNumber5() {
    return this.data.identificationType === 'regulation_number_5';
  }

  useName() {
    return this.data.identificationType === 'name';
  }

  isAlphanumericCowNo() {
    return this.data.cowNoPattern === 'alphanumeric';
  }

  farmType() {
    if (this.data.farmKind === '乳用') {
      return Farm.farmTypeMilk;
    } else {
      return Farm.farmTypeBeef;
    }
  }

  isMilk() {
    return this.data.farmKind === '乳用' ? true : false;
  }

  isBeef() {
    return this.data.farmKind === '肉用' ? true : false;
  }

  isDairy() {
    return this.data.farmTypeDairy;
  }

  isBreeding() {
    return this.data.farmTypeBreeding;
  }

  isFattening() {
    return this.data.farmTypeFattening;
  }

  isStandard() {
    return this.data.managementStyle === 'standard';
  }

  isDepositor() {
    return this.data.managementStyle === 'depositor';
  }

  isAgent() {
    return this.data.managementStyle === 'agent';
  }

  useAlertLocation() {
    return this.data.alertLocation;
  }

  isUMFull() {
    return this.data.contractedPlan === 'full';
  }

  isUMLight() {
    return this.data.contractedPlan === 'light';
  }

  showHeatIndexFattening() {
    return this.data.showHeatIndexFattening;
  }

  allowDuplicationCowNo() {
    return this.data.allowDuplicationCowNo;
  }

  useDuplicationOption() {
    return this.useCowNo() || this.useName();
  }

  fatteningStageBaseDate() {
    return this.data.fatteningStageBaseDate;
  }

  useAlertDysstasia() {
    return this.data.alertDysstasia;
  }

  farmTypeDescription() {
    let descriptions = [];

    if (this.isDairy()) {
      descriptions.push('酪農');
    }

    if (this.isBreeding()) {
      descriptions.push('繁殖');
    }

    if (this.isFattening()) {
      descriptions.push('肥育');
    }

    return descriptions.join(' ');
  }

  isAdminFarm() {
    return this.data.adminFarm;
  }

  isInactive() {
    return this.data.usageStatus === 'inactive';
  }

  isNonCustomer() {
    return this.data.usageStatus === 'non_customer';
  }

  useSensorTypeCaption() {
    const sensorTypeMapping = {
      'use_neck_sensor': '首センサー',
      'use_ear_sensor': 'イヤタグセンサー',
      'use_both_sensor': 'イヤタグセンサーと首センサーを併用'
    };

    return sensorTypeMapping[this.data.useSensorType];
  }
}
